
.navbar{
    background-color: var(--color-palette1);
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;
    font: var(--font-family-en);
}
.navbar__container div{
    display: flex;
    align-items: center;
    justify-content:space-evenly;
}

.navbar__container p{
    padding: 10px .5rem;
    margin: 4px;
    color: white;
    font-size: 1rem;
    font-weight: 500;

}


.navbar__container p:hover{
    box-shadow: inset 0px -4px var(--color-palette2);
    transition: ease .3s;
    cursor: pointer;
}

.navbar__menu{
    margin-left: 1rem;
    display: none;
    position: absolute; 
    background-color: var(--color-palette1);
    top: 1rem;

}

.navbar__menu_container{
    display: flex;
    flex-direction: column;
    position: absolute;
    white-space: nowrap;
    min-width: fit-content;
    background-color: rgba(69, 69, 69, .9);
    z-index: 1;
    padding-top: 2rem;
}

.navbar__menu_close{
    z-index: 2;
    margin-top: .5rem;
}


.navbar__menu p{
    padding: 1rem;
    line-height: 1.5rem;
    font-size: 1rem;
    margin: 0;
    color: white;
}

.navbar__menu_container p:hover{
    box-shadow: inset 0px -4px var(--color-palette2);
    cursor: pointer;
}

@media screen and (max-width: 1080px){
    .navbar__container p{
        padding: .65rem;
        font-size: .8rem;
        margin: 3px;
    }
}


@media screen and (max-width: 700px){
    .navbar__container{
        display: none;
    }
    .navbar__menu{
        display: flex;
    }

  
}



