.calendar{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin:2rem;
    flex-direction: column;
    width: 75%;
    margin: auto;
}

.calendar embed{
    margin: 2rem 0;
}
