.faculty{
    background:linear-gradient(180deg, rgba(144, 170, 203, 1) 0%, rgba(255, 176, 133, 1) 88%, rgba(249, 213, 167, 1) 100%);

}

.faculty__slides{
    display: flex;   
    flex-wrap: wrap;
    justify-content: center;
}

.faculty__slides > *{
    background-color: rgba(250, 255, 250, .65);
    padding: 0 2rem;
    border-radius: 10px;
}

.faculty__background{
}   

.faculty__background img{
    position: absolute;
    width: 100vw;
    clip: 100%;

    margin-top: -5px;
    object-fit: contain;

    mask-image: linear-gradient(to bottom, rgba(0,0,0, 1) 0%, rgba(0,0,0, .5) 80%,rgba(0,0,0,0)100%);
    z-index: -1;
}