.driving{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.driving img{
    justify-self: flex-start;
    max-height: 85vh;
    max-width: 90vw;
    object-fit:contain;
    margin-bottom: 2rem;
}