
.about-us{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: url(../images/background1.JPG);
    
    background-size: cover;
}
.about-us__text{
    display: flex;
    flex-direction: column;
    margin: 2rem;
    width: 60%;
}

.about-us__background{
    width: 100%;
    height: 100%;
}

.about-us__background > img{
    position: absolute;
    object-fit:cover;

    width: 100%;
    height: 100%;
    
    mask-image: linear-gradient(to bottom, rgba(0,0,0, 1) 0%, rgba(0,0,0, .5) 80%,rgba(0,0,0,0)100%);
    z-index: -1;
}

.about-us__text-translate_panel{
    margin: 1rem 0;
}

.about-us__text-translate_panel button{
    width: fit-content;
    background-color: rgba(254, 241, 230, 0.65);
    line-height: 0;
    padding: 1rem;;
    border: none;
}

.about-us__text-translate_panel button:hover{
    cursor:pointer;
    box-shadow: inset 0px 0px 2px gray;
}

.about-us__text-translate--selected{
    background-color: rgba(254, 241, 230, .85) !important;
}


.about-us__text > div {
    background-color: rgba(254, 241, 230, .85);
}

h3{
    margin: 1rem 2rem;
}

.about-us__text_subsection1{
    display: flex;
    flex-direction: row;
    padding: 1rem;
    justify-content: center;
}


.about-us__text_subsection1 > div{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    min-width: 35%;
}

.about-us__text_subsection1 > div:first-child{
    border-right: 1px solid gray;
}

.about-us__text_subsection2{
    margin: 0 2rem;
}

@media screen and (max-width: 880px) {
    .about-us__text_subsection1{
        flex-direction: column;
    }

    .about-us__text_subsection1 > div:first-child{
        border:none;
    }
    
}