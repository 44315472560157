.student-info{
    margin: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.student-info img, .student-info embed{
    max-height: 100vh;
    width: 80%;
    min-height: 80vh;

}

.student-info__text-translate_panel{
    margin: .5rem;
}

.student-info__text-translate_panel button{
    width: fit-content;
    background-color: rgba(255, 255, 255, 0.9) !important;
    line-height: 0;
    padding: 1rem;
    border: none;
}

.student-info__text-translate_panel button:hover{
    cursor:pointer;
    box-shadow: inset 0px 0px 1px gray;
}
.student-info__text-translate--selected{
    background-color: rgba(254, 241, 230, 1) !important;
    box-shadow: inset 0px 0px 1px black;
}
