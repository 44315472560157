.footer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 1rem;
    background-color: #FFB085 ;

    
}

.footer__company-name{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;
}

.footer__contact-us div:nth-child(5){
    display: flex;
    flex-direction: row;
}

.footer a, .footer a:visited{
    text-decoration: none;
}

.footer a:hover{
    text-decoration: underline;
    color: black !important;
}

.footer a:hover{
    cursor: pointer;
    text-decoration: underline;
    color: navy;
}

@media screen and (max-width:680px) {
    .footer{
        flex-direction: column;
    }
}