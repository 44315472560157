.forms{
    margin: 2rem;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
}

.forms__heading{
    display: flex;  
    margin: 1rem 0;
}
.forms__registration{
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 1rem 0rem;
    align-items: baseline;
}

.forms__registration a{
    color: blue;
    text-decoration: underline;
}

.forms__registration a:visited{
    color: blue;
    text-decoration: underline;
}

.forms__registration a:hover{
    color: navy;
    text-decoration: underline;
    cursor: pointer;
}